import React, { Component } from 'react';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

class GameItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.message.text,
      visible: false,
    };
  }

  render() {
    const { message } = this.props;

    return (
      <div className="game-content">
      <div className="game-date">{message.date}<span className="game-date-time">{message.startTime}</span></div>
      <div className="game-title">{message.gameName}<div>{message.gameInfo}</div></div>
      <div className="game-score-center">
      <div className="game-score">{message.kScore} - {message.aScore}</div>
      </div>
      <div className="game-match">
      <div className="game-match-name">國學院大學久我山高校</div>
      <div className="game-match-sub">VS</div>
      <div className="game-match-name">{message.against}</div>
      </div>
      <div className="game-score-center">
      <div className="game-match-place">＠{message.place}</div>
    </div>
</div>
    );
  }
}

export default GameItem;
