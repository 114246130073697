import React from 'react';
import { Link } from 'gatsby';

import wada from '../../images/map/wada.png';
import ishimizu from '../../images/map/ishimizu.png';
import ishizaka from '../../images/map/ishizaka.png';
import okabe from '../../images/map/okabe.png';
import itou from '../../images/map/2021-1-伊藤龍生.jpg';
import arai from '../../images/map/2021-3-新井貴久.jpg';
import * as ROUTES from '../../constants/routes';

class Mappage extends React.Component {

  render() {
    return (
      <div className="map-japan">
      <h2 className="content-title-black">地方出身部員の声</h2>

      <div className="country-main">

      <div className="country-content">
      <div className="country-comment">久我山高校で文武両道を実践したいと思いました！</div>
      <div className="country-board">
      <Link to={ROUTES.ARAI}>
      <img className="ob-ajust" alt="sakai" src={arai} />
      <div className="country-title"><span className="wordspace">2021年卒</span>神奈川県出身</div>
      <div className="country-title"><span className="wordspace-name">新井</span>貴久</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="country-content">
      <div className="country-comment">長時間の移動で、遅刻などが心配でした！</div>
      <div className="country-board">
      <Link to={ROUTES.ITOU}>
      <img className="ob-ajust" alt="sakai" src={itou} />
      <div className="country-title"><span className="wordspace">2年生</span>埼玉県出身</div>
      <div className="country-title"><span className="wordspace-name">伊東</span>龍生</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="country-content">
      <div className="country-comment">東京に来る上で不安だった事は、電車と人の量です！</div>
      <div className="country-board">
      <Link to={ROUTES.ISHIMIZU}>
      <img className="ob-ajust" alt="sakai" src={ishimizu} />
      <div className="country-title"><span className="wordspace">2021年卒</span>新潟県出身</div>
      <div className="country-title"><span className="wordspace-name">石水</span>鴻</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="country-content">
      <div className="country-comment">東京国体選抜では貴重な経験ができました！</div>
      <div className="country-board">
      <Link to={ROUTES.ISHIZAKA}>
      <img className="ob-ajust" alt="sakai" src={ishizaka} />
      <div className="country-title"><span className="wordspace">2021年卒</span>愛知県出身</div>
      <div className="country-title"><span className="wordspace-name">石坂</span>悠月</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="country-content">
      <div className="country-comment">通学時間が長いので、勉強の時間に充ててます！</div>
      <div className="country-board">
      <Link to={ROUTES.OKABE}>
      <img className="ob-ajust" alt="sakai" src={okabe} />
      <div className="country-title"><span className="wordspace">2021年卒</span>茨城県出身</div>
      <div className="country-title"><span className="wordspace-name">岡部</span>雅大</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="country-content">
      <div className="country-comment">標準語を意識しすぎて、片言になり苦労しました！</div>
      <div className="country-board">
      <Link to={ROUTES.WADA}>
      <img className="ob-ajust" alt="sakai" src={wada} />
      <div className="country-title"><span className="wordspace">2021年卒</span>大阪府出身</div>
      <div className="country-title"><span className="wordspace-name">和田</span>笙平</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      </div>
      </div>
    );
  }
}

export default Mappage;
