import React from 'react';

import GameItem from './GameItem';

const GameList = ({
  messages,
}) => (
  <div className="game-main">
    {messages.map(message => (
      <GameItem
        message={message}
      />
    ))}
  </div>
);

export default GameList;
