import React, { Component } from 'react'
import weekup from "../../images/sponsor/mowbray_logo.png"
import highfive from "../../images/sponsor/highfive.png"

class Sponsor extends Component {
  contact = ()=> {
      window.open('https://www.mowbraysports.jp', "_blank");
    }

    highfive = ()=> {
        window.open('https://www.youtube.com/@highfive_basketball/videos', "_blank");
      }
render() {
    return (
<div className="sponser-main">

      <div className="sponser-area">
      <div className="sponsor-title">Supported By</div>
      <div className="sponsor-content" onClick={this.contact}>
      <img className="sponsor-logo" alt="weekpup" src={weekup} />
      <div className="sponsor-text">M.モゥブレィスポーツ</div>
      </div>
      </div>

      <div className="sponser-area">
      <div className="sponsor-content" onClick={this.highfive}>
      <img className="sponsor-logo" alt="weekpup" src={highfive} />
      </div>
      </div>

</div>
  )
  }
}

export default Sponsor;
