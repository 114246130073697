import React from 'react';
import twitter from '../../images/twitter-icon.png';
import instagram from '../../images/instagram-icon.png';

class SocialMedia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  twitter = ()=> {
      window.open('https://twitter.com/kkbbc5?s=11', "_blank");
    }
    instagram = ()=> {
        window.open('https://instagram.com/kugayama_bbc?igshid=lg843fs4t0mx', "_blank");
      }

  render() {
    return (
      <div className="social-media">
      <h2 className="content-title-white-social">Social Media</h2>

      <div className="social-media-main">
      <div className="social-media-sub">
      <img className="social-media-icon" onClick={this.twitter} alt="twitter" src={twitter} />
      <img className="social-media-icon" onClick={this.instagram} alt="instagram" src={instagram} />
      </div>
      </div>

      </div>
    );
  }
}

export default SocialMedia;
