import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// import firebase from 'firebase'
import NoticeList from './NoticeList';

class Notice extends Component {
  _initFirebase = false;


  constructor(props) {
    super(props);

    this.state = {
      text: '',
      title: '',
      loading: false,
      messages: [],
      limit: 6,
      curTime : new Date().toISOString().substring(0,10),
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.onListenForMessages();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.props.firebase
      .messages()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const messageObject = snapshot.val();

        if (messageObject) {
          const messageList = Object.keys(messageObject).reverse().map(key => ({
            ...messageObject[key],
            uid: key,
          }));

          this.setState({
            messages: messageList,
            loading: false,
          });

          if(this.state.messages.length >= 1){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[0].image}`).getDownloadURL().then((url) => {
            this.state.messages[0].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[0].text = this.state.messages[0].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 2){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[1].image}`).getDownloadURL().then((url) => {
            this.state.messages[1].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[1].text = this.state.messages[1].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 3){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[2].image}`).getDownloadURL().then((url) => {
            this.state.messages[2].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[2].text = this.state.messages[2].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 4){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[3].image}`).getDownloadURL().then((url) => {
            this.state.messages[3].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[3].text = this.state.messages[3].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 5){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[4].image}`).getDownloadURL().then((url) => {
            this.state.messages[4].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[4].text = this.state.messages[4].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        if(this.state.messages.length >= 6){
          this.props.firebase.storage1().ref(`/images/${this.state.messages[5].image}`).getDownloadURL().then((url) => {
            this.state.messages[5].image = url;
            this.setState({messages: this.state.messages})
          })
          // this.state.messages[5].text = this.state.messages[5].text.replace('\\n', '\n');
          // this.setState({messages: this.state.messages})
        }

        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.messages().off();
  }

  render() {
    const { messages, loading, curTime } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="margin-submit">
            {loading && <div>Loading ...</div>}
<div className="">
<h2 className="content-title-black2">お知らせ</h2>
<div className="title-underline"></div>
            {messages && (
              <NoticeList
                authUser={authUser}
                messages={messages}
                onEditMessage={this.onEditMessage}
                onRemoveMessage={this.onRemoveMessage}
              />
            )}

            {!messages && <div>There are no messages ...</div>}

</div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Notice);
