import React, { Component } from 'react';
import Rodal from 'rodal';

// include styles
import 'rodal/lib/rodal.css';

class NoticeItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editMode: false,
      editText: this.props.message.text,
      visible: false,
      device: 'pc',
    };
  }

  show() {
      this.setState({ visible: true });
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'relative';
      document.body.style.height = '100%';
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.position = 'relative';
  }

  hide() {
      this.setState({ visible: false });
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.documentElement.style.overflow = 'unset';
      document.documentElement.style.position = 'unset';
  }

  componentDidMount() {
      const ua = window.navigator.userAgent.toLowerCase();
      if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
          this.setState({ device: 'sp' });
      } else {
          this.setState({ device: 'pc' });
      }
  }

  render() {
    const { message } = this.props;

    return (
      <div className="noticeDiv">

          <div className="noticeCard" onClick={this.show.bind(this)}>

            <div className="noticeText">
            {message.category =="記事" && (
            <div className="noticeCategoryNews">{message.category}</div>
          )}
          {message.category =="お知らせ" && (
          <div className="noticeCategoryNotice">{message.category}</div>
        )}
            {this.state.device =="pc" && (
            <div className="noticeCreatedAt">{message.createdAt}</div>
            )}
            {this.state.device =="pc" && (
            <div className="noticeTitle">{message.title}</div>
            )}

                      {this.state.device =="sp" && (
                        <div className="noticecolumn">
                        <div className="noticeTitle">{message.title}</div>
                      <div className="noticeCreatedAt">{message.createdAt}</div>
                      </div>
                      )}
            </div>

          </div>

          <Rodal animation="slideUp" visible={this.state.visible} onClose={this.hide.bind(this)}>
<img className="rodal-notice-Img" src={ message.image } alt={message.text} />
              <h3 className="notice-textsize">{message.title}</h3>
              <div className="notice-main-text">
              <div>{message.text1}</div>
              <div>{message.text2}</div>
              <div>{message.text3}</div>
              <div>{message.text4}</div>

              {message.urltext &&<div className="noticeUrl">
              <a className="noticeTextUrl" href={message.url} target="_blank" rel="noopener">{message.urltext}</a></div>
              }

              {message.urltext2 && <div className="noticeUrl">
              <a className="noticeTextUrl" href={message.url2} target="_blank" rel="noopener">{message.urltext2}</a></div>
              }
              </div>
              <div className="noticeDate">{message.createdAt}</div>
          </Rodal>
      </div>
    );
  }
}

export default NoticeItem;
