import React from 'react';
// import { Link } from 'gatsby';
// import * as V from 'victory';
// import ReactDOM from 'react-dom';
import { VictoryPie } from 'victory';
// import * as ROUTES from '../../constants/routes';
import oneday1 from '../../images/oneday/oneday_1.jpg';
import oneday2 from '../../images/oneday/oneday_2.jpg';
import oneday3 from '../../images/oneday/oneday_3.jpg';
import oneday4 from '../../images/oneday/oneday_4.jpg';
import oneday5 from '../../images/oneday/oneday_5.jpg';
import oneday6 from '../../images/oneday/oneday_6.jpg';
import onedayDefualt from '../../images/oneday/oneday_defualt.png';

const sampleData=[
    { x: "睡眠", y: 40 },
    { x: "通学", y: 6 },
    // { x: "自習", y: 4 },
    { x: "授業", y: 12 },
    { x: "昼食", y: 6 },
    { x: "自主トレ", y: 6 },
    { x: "練習", y: 10 },
    { x: "下校", y: 6 },
    { x: "夕食", y: 6 },
    { x: "自由時間", y: 10 },
  ];

class OneDaypage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authUser: null,
      clickedData: null,
      clickNow: null,
    };
  }

  render() {
    const project = () => {
      switch(this.state.clickNow) {

        case "下校":
        return <div className="daily-ishizaka">
        <img className="daily-ishizaka-img" alt="sakai" src={oneday1}/>
        <div className="daily-ishizaka-text"><div>18:30~19:20</div>登校時のように単語帳を開くようにしています！
</div>
</div>;

case "通学":
return <div className="daily-ishizaka">
<img className="daily-ishizaka-img" alt="sakai" src={oneday1}/>
<div className="daily-ishizaka-text"><div>7:30-8:20</div> 通学中は単語帳などを見て勉強するようにしています！
</div>
</div>;

        case "授業":
         return <div className="daily-ishizaka">
         <img className="daily-ishizaka-img" alt="sakai" src={oneday3}/>
         <div className="daily-ishizaka-text"><div>8:45-12:15</div>テストで困らないように、日々集中して取り組んでいます！
</div>
</div>;
        case "昼食":
        return <div className="daily-ishizaka">
        <img className="daily-ishizaka-img" alt="sakai" src={oneday4}/>
        <div className="daily-ishizaka-text"><div>12:20~13:00</div>選手としてコンディションを整えるためにも、栄養をきっちり取っています！
</div>
</div>;

        case "自主トレ":
        return <div className="daily-ishizaka">
        <img className="daily-ishizaka-img" alt="sakai" src={oneday5}/>
        <div className="daily-ishizaka-text"><div>13:00-15:00</div>シューティングや、基礎トレーニングを中心にやっています！
</div>
</div>;

        case "練習":
        return <div className="daily-ishizaka">
        <img className="daily-ishizaka-img" alt="sakai" src={oneday6}/>
        <div className="daily-ishizaka-text"><div>15:00-18:00</div>全国大会に出るために、日々仲間と高め合っています！
</div>
</div>;



        case "夕食":
        return <div className="daily-ishizaka">
        <img className="daily-ishizaka-img" alt="sakai" src={onedayDefualt}/>
        <div className="daily-ishizaka-text"><div>20:00-20:30</div>家でもきっちり栄養をとっています！　　　　　　
</div>
</div>;

case "自由時間":
return <div className="daily-ishizaka">
<img className="daily-ishizaka-img" alt="sakai" src={oneday2}/>
<div className="daily-ishizaka-text"><div>21:00-23:00</div>1時間は自習をするようにして、残りの時間は体のケアなどをしています！
</div>
</div>;

        default:
        return <div className="daily-ishizaka">
        <img className="daily-ishizaka-img" alt="sakai" src={onedayDefualt}/>
        <div className="daily-ishizaka-text">1年生の石水です。私の1日を紹介していきます！<div>円グラフを<span className="yellow-text">クリック/タップ</span>してください！</div>
</div>
</div>;
      }
    }

    return (
      <div className="oneday">
      <h2 className="content-title-white">石水くんの1日</h2>
      <div className="oneday-align">

      <div className="oneday-content">
      <div className="daily-align">
      <VictoryPie
      events={[{
  target: "data",
  eventHandlers: {
    onClick: () => {
      console.log("clicked");
      return [
        // {
        //   target: "data",
        //   mutation: ({ style }) => {
        //     return style.fill === "yellow" ? null : { style: { fill: "yellow" } };
        //   }
        // },
         {
          target: "labels",
          mutation: ({ text }) => {
            // this.state.clickNow = text;
            this.setState({clickNow: text});
                  console.log(this.state.clickNow);
                  if(this.state.clickNow !== "睡眠" || this.state.clickNow !=="自由時間"){
                    console.log("it worked");
                  project();
                  }
            this.setState({clickedData: text});
            // return text === "clicked" ? null : { text: "clicked" };
          }
        }
      ];
    }
  }
}]}
      startAngle={-20}
      // endAngle={450}
      colorScale={["#000032", "#000045", "#000059","#00006c", "#000080", "#000094", "#0000a7", "#0000bb", "#0000ce", "#0000e2" ]}
      style={{
        data: {
  fillOpacity: 0.9, stroke: "white", strokeWidth: 2
},
  labels: {
    fontSize: 17, fill: "white"
  }
}}
animate={{
  duration: 2000
}}
data={sampleData}
/>
      </div>
      </div>

      <div className="oneday-content">
      {project()}
      </div>

      </div>
      </div>
    );
  }
}

export default OneDaypage;
