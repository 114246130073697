import React, { Component } from 'react';

import { AuthUserContext } from '../Session';
import { withFirebase } from '../Firebase';
// import firebase from 'firebase'
import GameList from './GameList';

class Game extends Component {
  _initFirebase = false;


  constructor(props) {
    super(props);

    this.state = {
      text: '',
      title: '',
      loading: false,
      messages: [],
      limit: 5,
      curTime : new Date().toLocaleString(),
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;
      this.onListenForMessages();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForMessages = () => {
    this.setState({ loading: true });

    this.props.firebase
      .games()
      .orderByChild('createdAt')
      .on('value', snapshot => {
        const messageObject = snapshot.val();

        if (messageObject) {
          const messageList = Object.keys(messageObject).reverse().map(key => ({
            ...messageObject[key],
            uid: key,
          }));

          this.setState({
            messages: messageList,
            loading: false,
          });
        } else {
          this.setState({ messages: null, loading: false });
        }
      });
  };

  componentWillUnmount() {
    this.props.firebase.games().off();
  }

  render() {
    const { messages, loading } = this.state;

    return (
      <AuthUserContext.Consumer>
        {authUser => (
          <div className="margin-submit">
            {loading && <div>Loading ...</div>}
            <div>
            <h2 className="content-title-black2">試合情報</h2>
            <div className="title-underline"></div>
            {messages && (
              <GameList
                authUser={authUser}
                messages={messages}
              />
            )}

            {!messages && <div>There are no messages ...</div>}

</div>
          </div>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

export default withFirebase(Game);
