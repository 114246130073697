import React from 'react';
import { Link } from 'gatsby';

import { AuthUserContext } from '../Session';
import SignOutButton from '../SignOut';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import kugayamaLogo from '../../images/footer_title.png'

const Navigation = () => (
  <AuthUserContext.Consumer>
    {authUser =>
      authUser ? (
        <NavigationAuth authUser={authUser} />
      ) : (
        <NavigationNonAuth />
      )
    }
  </AuthUserContext.Consumer>
);

const NavigationAuth = ({ authUser }) => (
  <div>
      {!!authUser.roles[ROLES.ADMIN] && (
    <li>
      <Link to={ROUTES.LANDING}>Landing</Link>
    </li>
  )}
      {!!authUser.roles[ROLES.ADMIN] && (
    <li>
      <Link to={ROUTES.HOME}>Home</Link>
    </li>
  )}
      {!!authUser.roles[ROLES.ADMIN] && (
    <li>
      <Link to={ROUTES.ACCOUNT}>Account</Link>
    </li>
  )}
    {!!authUser.roles[ROLES.ADMIN] && (
      <li>
        <Link to={ROUTES.ADMIN}>Admin</Link>
      </li>
    )}
        {!!authUser.roles[ROLES.ADMIN] && (
    <li>
      <SignOutButton />
    </li>
  )}
  <div className="footer-main">
    <div className="footer-content">
    <div className="footer-title"><img alt="Homepage" src={kugayamaLogo} /></div>
    <div className="footer-text">〒168-0082　東京都杉並区久我山1-9-1</div>
    <div className="footer-text">phone:03-3334-1151 ／ fax:03-3335-1233</div>
    <div className="footer-text">監督:酒井良幸</div>
    </div>
    <div className="footer-content">
<iframe className="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.7307852166623!2d139.5914156152588!3d35.68363033019375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f1e429a79be9%3A0xd9a09d80d787e2c3!2z5ZyL5a246Zmi5aSn5a245LmF5oiR5bGx5Lit5a2m5qChIOmrmOetieWtpuagoQ!5e0!3m2!1sja!2sjp!4v1595002200302!5m2!1sja!2sjp" width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
    </div>
</div>
    <div className="copyright">
Copyright ©　國學院大學久我山高等学校バスケットボール部, All Rights Reserved.
    </div>
  </div>
);

const NavigationNonAuth = () => (
  <div>
  <div className="footer-main">
    <div className="footer-content">
    <div className="footer-title"><img alt="Homepage" src={kugayamaLogo} /></div>
    <div className="footer-text">〒168-0082　東京都杉並区久我山1-9-1</div>
    <div className="footer-text">phone:03-3334-1151 ／ fax:03-3335-1233</div>
    <div className="footer-text">監督:酒井良幸</div>
    </div>
    <div className="footer-content">
<iframe className="googleMap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3240.7307852166623!2d139.5914156152588!3d35.68363033019375!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f1e429a79be9%3A0xd9a09d80d787e2c3!2z5ZyL5a246Zmi5aSn5a245LmF5oiR5bGx5Lit5a2m5qChIOmrmOetieWtpuagoQ!5e0!3m2!1sja!2sjp!4v1595002200302!5m2!1sja!2sjp" width="600" height="450" frameBorder="0" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
    </div>
</div>
    <div className="copyright">
Copyright ©　國學院大學久我山高等学校バスケットボール部, All Rights Reserved.
    </div>
    </div>
);

export default Navigation;
