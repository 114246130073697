import React from 'react';
import { Link } from 'gatsby';
import SwiftSlider from 'react-swift-slider'

import example1 from '../../images/example1.png';
import example2 from '../../images/example2.png';
import example3 from '../../images/example3.png';

const data =  [
  {'id':'1','src':example1},
  {'id':'2','src':example2},
  {'id':'3','src':example3}
];

class FAQpage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        device: 'pc',
    };
}
componentDidMount() {
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('iphone') > 0 || ua.indexOf('ipod') > 0 || ua.indexOf('android') > 0 && ua.indexOf('mobile') > 0) {
        this.setState({ device: 'sp' });
    } else {
        this.setState({ device: 'pc' });
    }
}

  render() {
    return (
      <div>
      <h2 className="content-title-info">お問い合わせ例 <div className="note">実際の返信内容とは異なる可能性がありますので、ご理解ください。</div></h2>

          {this.state.device =="pc" && (
      <div className="contact-example">
      <img alt="sakai" src={example1} />
      <img alt="sakai" src={example2} />
      <img alt="sakai" src={example3} />
      </div>
    )}

    {this.state.device =="sp" && (
      <div className="contact-example-mobile">
<SwiftSlider data={data} enableNextAndPrev={false} height={530} interval={10000}/>
</div>
)}
      </div>
    );
  }
}

export default FAQpage;
