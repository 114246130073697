import React from 'react';
import trophy from '../../images/trophy.png';
import Rodal from 'rodal';


class Performance extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
    };
  }

//   componentDidMount() {
// document.body.style.overflow = 'hidden';
// document.body.style.overflow = 'unset';
//   }

  show() {
      this.setState({ visible: true });
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'relative';
      document.body.style.height = '100%';
      document.documentElement.style.overflow = 'hidden';
      document.documentElement.style.position = 'relative';
      // document.body.style.msTouchAction = "none";
  }

  hide() {
      this.setState({ visible: false });
      document.body.style.overflow = 'unset';
      document.body.style.position = 'unset';
      document.documentElement.style.overflow = 'unset';
      document.documentElement.style.position = 'unset';
      // document.body.style.msTouchAction = "unset";
  }

  render() {
    return (
      <div>
      <div className="performance">
      <h2 className="content-title-white">実績</h2>
      <div className="performance-main">
      <div className="interhigh">
      <div className="board-interhigh">
      <img alt="sakai" src={trophy} />
      <div className="performance-title">インターハイ</div>
      <div className="performance-result">2023年 東京都予選<div><span className="performance-rank">第<span className="performance-num">3</span>位</span></div></div>
      <div className="performance-subresult">（2015年〜2017年 3年連続出場！）</div>
      </div>
      </div>
      <div className="wintercup">
      <div className="board-wintercup">
      <img alt="sakai" src={trophy} />
      <div className="performance-title">ウィンターカップ</div>
      <div className="performance-result">2023年 東京都予選<div><span className="performance-rank">第<span className="performance-num">３</span>位</span></div></div>
      <div className="performance-subresult">（2021年～2022年 2年連続出場！）</div>
      </div>
      </div>
      </div>
                  <div className="performance-btn" onClick={this.show.bind(this)}>過去の実績はこちら >></div>

      <Rodal animation="zoom" visible={this.state.visible} onClose={this.hide.bind(this)}>
      <div>
      <table>
      <tr className="plan-first">
        <th className="table-year">　</th>
        <td className="plan1_i"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
        <td className="plan2_i"><i className="fa fa-check-circle" aria-hidden="true"></i></td>
      </tr>
            <tr>
              <th className="plan0"></th>
              <th className="plan1"><div className="price"><img className="plan-img" alt="sakai" src={trophy} />インターハイ</div></th>
              <th className="plan2"><div className="price"><img className="plan-img" alt="sakai" src={trophy} />ウィンターカップ</div></th>
            </tr>
      </table>
      <div>
            <table className="table-scroll">
            <tr>
              <th className="table-year-color">2019年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color">2回戦進出<div>(14度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">2018年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
                <tr>
              <th className="table-year-color">2017年</th>
              <td className="plan1_color">1回戦進出<div>(22度目出場:福島)</div></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">2016年</th>
              <td className="plan1_i">2回戦進出<div>(21度目出場:広島)</div></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
              <th className="table-year-color">2015年</th>
              <td className="plan1_color">2回戦進出<div>(20度目出場:京都)</div></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">2014年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">2013年</th>
              <td className="plan1_color">2回戦進出<div>(19度目出場:大分)</div></td>
              <td className="plan2_color">ベスト8進出<div>(13度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">2012年</th>
              <td className="plan1_i">2回戦進出<div>(18度目出場:石川)</div></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">2011年</th>
              <td className="plan1_color">1回戦進出<div>(17度目出場:秋田)</div></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">2010年</th>
              <td className="plan1_i">1回戦進出<div>(16度目出場:沖縄)</div></td>
              <td className="plan2_i">2回戦進出<div>(12度目出場)</div></td>
            </tr>
            <tr>
            <th className="table-year-color">2009年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">2008年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">2007年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">2006年</th>
              <td className="plan1_i">2回戦進出<div>(15度目出場:大阪)</div></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">2005年</th>
              <td className="plan1_color">ベスト16進出<div>(14度目出場:千葉)</div></td>
              <td className="plan2_color">ベスト8進出<div>(11度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">2004年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i">ベスト8進出<div>(10度目出場)</div></td>
            </tr>
            <tr>
            <th className="table-year-color">2003年</th>
              <td className="plan1_color">2回戦進出<div>(13度目出場:長崎)</div></td>
              <td className="plan2_color">ベスト8進出<div>(9度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">2002年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">2001年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">2000年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1999年</th>
              <td className="plan1_color">2回戦進出<div>(12度目出場:岩手)</div></td>
              <td className="plan2_color">ベスト16進出<div>(8度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">1998年</th>
              <td className="plan1_i">1回戦進出<div>(11度目出場:高知)</div></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1997年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1996年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1995年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1994年</th>
              <td className="plan1_i">2回戦進出<div>(10度目出場:富山)</div></td>
              <td className="plan2_i">準優勝<div>(7度目出場)</div></td>
            </tr>
            <tr>
            <th className="table-year-color">1993年</th>
              <td className="plan1_color">2回戦進出<div>(9度目出場:栃木)</div></td>
              <td className="plan2_color">ベスト8進出<div>(6度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">1992年</th>
              <td className="plan1_i">1回戦進出<div>(8度目出場:宮崎)</div></td>
              <td className="plan2_i">ベスト8進出<div>(5度目出場)</div></td>
            </tr>
            <tr>
            <th className="table-year-color">1991年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1990年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1989年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color">ベスト16進出<div>(4度目出場)</div></td>
            </tr>
            <tr>
              <th className="table-year">1988年</th>
              <td className="plan1_i">ベスト16進出<div>(7度目出場:神戸)</div></td>
              <td className="plan2_i">2回戦進出<div>(3度目出場)</div></td>
            </tr>
            <tr>
            <th className="table-year-color">1987年</th>
              <td className="plan1_color">1回戦進出<div>(6度目出場:北海道)</div></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1986年</th>
              <td className="plan1_i">1回戦進出<div>(5度目出場:岡山)</div></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1985年</th>
              <td className="plan1_color">ベスト16進出<div>(4度目出場:七尾)</div></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1984年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1983年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1982年</th>
              <td className="plan1_i">3位<div>(3度目出場:鹿児島)</div></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1981年</th>
              <td className="plan1_color">ベスト8進出<div>(2度目出場:川崎)</div></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1980年</th>
              <td className="plan1_i"></td>
              <td className="plan2_i"></td>
            </tr>
            <tr>
            <th className="table-year-color">1979年</th>
              <td className="plan1_color"></td>
              <td className="plan2_color"></td>
            </tr>
            <tr>
              <th className="table-year">1978年</th>
              <td className="plan1_i">2回戦進出<div>(1度目出場:山形)</div></td>
              <td className="plan2_i"></td>
            </tr>
      </table>
      </div>
    </div>
      </Rodal>
      </div>
      </div>
    );
  }
}

export default Performance;
