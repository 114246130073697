import React, { Component } from 'react'
import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import MagicSliderDots from 'react-magic-slider-dots';
// import 'react-magic-slider-dots/dist/magic-dots.css';
import top_image1 from '../../images/topImage/top1.jpg';
import top_image2 from '../../images/topImage/top2.jpg';
import top_image3 from '../../images/topImage/top3.jpg';
import top_image4 from '../../images/topImage/top4.jpg';
// import top_image5 from '../../images/topImage/top5.jpg';
import SwiftSlider from 'react-swift-slider'

const data =  [
  {'id':'1','src':top_image1},
  {'id':'2','src':top_image2},
  {'id':'3','src':top_image3},
  {'id':'4','src':top_image4}
  // ,
  // {'id':'5','src':top_image5}
];

class TopImage extends Component {

render() {
    return (
      <div className="adjust-top-img">
<SwiftSlider data={data} enableNextAndPrev={false} height={700}/>
</div>
  )
  }
}

export default TopImage;
