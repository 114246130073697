import React from 'react';
import sakai from '../../images/ad_sakai.png';
import school from '../../images/ad_kugayama.png';
import ad1 from '../../images/ad_kihon.png';
import ad2 from '../../images/ad_ohyo.png';

class Ads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  ad1 = ()=> {
      window.open('https://www.tandh.net/basketball_1506.php', "_blank");
    }
    ad2 = ()=> {
        window.open('https://www.tandh.net/basketball_1510.php', "_blank");
      }
  sakai = ()=> {
      window.open('https://basketball-play.com/kugayama/info/', "_blank");
    }
    school = ()=> {
        window.open('https://www.kugayama-h.ed.jp/', "_blank");
      }

  render() {
    return (
      <div className="ads-bottom">

      <div className="ads-bottom-main">

      <img className="ads-bottom-icon" onClick={this.ad1} alt="twitter" src={ad1} />
      <img className="ads-bottom-icon" onClick={this.ad2} alt="twitter" src={ad2} />
      <img className="ads-bottom-icon" onClick={this.sakai} alt="twitter" src={sakai} />
      <img className="ads-bottom-icon" onClick={this.school} alt="instagram" src={school} />

      </div>

      </div>
    );
  }
}

export default Ads;
