import React from 'react';

import NoticeItem from './NoticeItem';

const NoticeList = ({
  messages,
}) => (
  <div className="noticeBoard">
    {messages.map(message => (
      <NoticeItem
        message={message}
      />
    ))}
  </div>
);

export default NoticeList;
