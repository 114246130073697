import React from 'react';
import { Link } from 'gatsby';

import tobori from '../../images/tobori.jpg';
import mukai from '../../images/mukai.jpg';
import kenta from '../../images/ob/interview_kenta.jpg';
import higashi from '../../images/ob/higashi20241.png';
import yanagida from '../../images/ob/yanagida0_2024.jpg';
import manabe from '../../images/ob/manabe.jpg';
import takeda from '../../images/ob/takeda-top.jpg';
import * as ROUTES from '../../constants/routes';

class OBpage extends React.Component {

  render() {
    return (
      <div className="ob-page">
      <h2 className="content-title-white">OBの声</h2>
      <div className="ob-main">


      <div className="ob-content">
      <div className="board-ob-tobori">
      <Link to={ROUTES.TAKEDA}>
      <img className="ob-ajust" alt="sakai" src={takeda} />
      <div className="ob-title"><span className="wordspace">横浜ビー・コルセアーズ</span></div>
      <div className="ob-title"><span className="wordspace-name">竹田</span>謙</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="ob-content">
      <div className="board-ob-tobori">
      <Link to={ROUTES.MANABE}>
      <img className="ob-ajust" alt="sakai" src={manabe} />
      <div className="ob-title"><span className="wordspace">証券会社</span>マーケティング</div>
      <div className="ob-title"><span className="wordspace-name">眞部</span>径</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>


      <div className="ob-content">
      <div className="board-ob-tobori">
      <Link to={ROUTES.YANAGIDA}>
      <img className="ob-ajust" alt="sakai" src={yanagida} />
      <div className="ob-title"><span className="wordspace">法政大学</span>経営学部</div>
      <div className="ob-title"><span className="wordspace-name">柳田</span>大斗</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>


      <div className="ob-content">
      <div className="board-ob-tobori">
      <Link to={ROUTES.HIGASHI}>
      <img className="ob-ajust" alt="sakai" src={higashi} />
      <div className="ob-title"><span className="wordspace">Bリーガー</span>富山グラウジーズ</div>
      <div className="ob-title"><span className="wordspace-name">東</span>宏輝</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>


      <div className="ob-content">
      <div className="board-ob-tobori">
      <Link to={ROUTES.KENTA}>
      <img className="ob-ajust" alt="sakai" src={kenta} />
      <div className="ob-title"><span className="wordspace">Bリーガー</span>熊本ヴォルターズ</div>
      <div className="ob-title"><span className="wordspace-name">ウィタカ</span>ケンタ</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="ob-content">
      <div className="board-ob-tobori">
      <Link to={ROUTES.TOBORI}>
      <img className="ob-ajust" alt="sakai" src={tobori} />
      <div className="ob-title"><span className="wordspace">明治大学</span>経営学部</div>
      <div className="ob-title"><span className="wordspace-name">戸堀</span>春輝</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      <div className="ob-content">
      <div className="board-ob">
      <Link to={ROUTES.MUKAI}>
      <img alt="sakai" src={mukai} />
      <div className="ob-title"><span className="wordspace">大手銀行</span>法人営業部</div>
      <div className="ob-title"><span className="wordspace-name">向井</span>和希</div>
      <div className="blue-text">記事を見る</div>
      <div className="blank-text"></div>
      </Link>
      </div>
      </div>

      </div>
      </div>
    );
  }
}

export default OBpage;
